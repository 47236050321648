@import url(https://p.typekit.net/p.css?s=1&k=mqo1opc&ht=tk&f=9775.9776.9805.9806.9807.9808&a=39118582&app=typekit&e=css);
.Toastify__toast {
    border-radius   : 5px;
}

.Toastify__toast--error {
    background      :   #d32f2f;
}

.Toastify__toast--success {
    border-radius   : 5px;
    background      : green;
}
.LoadingView
{
	height : 100%;
	width  : 100%;
}
:root {
	--background-color: rgba(114, 119, 143, 1.0);

	--peer-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
	--peer-border: 1px solid rgba(255, 255, 255, 0.15);
	--peer-empty-avatar: url(../../static/media/buddy.403cb9f6.svg);
	--peer-bg-color: rgba(42, 75, 88, 0.9);
	--peer-video-bg-color: rgba(0, 0, 0, 0.75);

	--active-speaker-border-color: rgba(255, 255, 255, 1.0);
	--selected-peer-border-color: rgba(55, 126, 255, 1.0);
}

html
{
	height: 100%;
	width: 100%;
	font-family: 'Roboto';
	font-weight: 300;
	margin : 0;
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
}

body
{
	height: 100%;
	width: 100%;
	font-size: 16px;
	margin: 0;
}

#edumeet
{
	height: 100%;
	width: 100%;
}

/************* CUSTOM BOOM BRANDING **************/

.MuiFab-root {
    background-color: #00000066 !important;
    color: white !important;
    border-radius: 7px !important;
}

.MuiFab-root:hover {
    background-color: #000000b0 !important;
}

.MuiFab-secondary,.MuiButton-containedSecondary {
    color: #fff;
    background-color: #b10000ab !important;
}

.MuiAppBar-colorPrimary {
     background-color: #0000004d !important;
}


/*
.webcam>div:first-child>div:first-child {
    background-color: #b10000ab !important;
    border-radius: 7px !important;
}
*/

.webcam.hover,.screen.hover {
    box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.05) inset, 0px 0px 0px 2px rgb(255 255 255 / 90%) !important;
}

.webcam {
    background-color: rgb(66 166 244 / 6%) !important;
}

.MuiDrawer-paper .MuiButton-containedSecondary {
    color: #fff;
    background-color: #b10000 !important;
}

.MuiDrawer-paper>div, .MuiDrawer-paper>div>.MuiPaper-elevation1 {
    background-color: #e9e9e9 !important;
}

.MuiButton-containedSecondary {
    background-color: #b10000 !important;
}

.MuiFab-secondary:hover {
    background-color: rgb(177 0 0)!important;
}

/*
.webcam>div:first-child>div:nth-child(3)>div:first-child>div:nth-child(2) {
    background-color: #00000057 !important;
    padding: 4px !important;
}
*/
/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * lexia:
 *   - http://typekit.com/eulas/00000000000000003b9b402f
 *   - http://typekit.com/eulas/00000000000000003b9b4030
 *   - http://typekit.com/eulas/00000000000000003b9b4031
 *   - http://typekit.com/eulas/00000000000000003b9b4032
 * lexia-advertising:
 *   - http://typekit.com/eulas/00000000000000003b9b4038
 *   - http://typekit.com/eulas/00000000000000003b9b4037
 *
 * © 2009-2020 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2020-09-04 12:02:57 UTC"}*/

@font-face {
font-family:"lexia-advertising";
src:url("https://use.typekit.net/af/83f30a/00000000000000003b9b4038/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/83f30a/00000000000000003b9b4038/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/83f30a/00000000000000003b9b4038/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;
}

@font-face {
font-family:"lexia-advertising";
src:url("https://use.typekit.net/af/6c3124/00000000000000003b9b4037/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/6c3124/00000000000000003b9b4037/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/6c3124/00000000000000003b9b4037/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:400;
}

@font-face {
font-family:"lexia";
src:url("https://use.typekit.net/af/241dff/00000000000000003b9b402f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/241dff/00000000000000003b9b402f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/241dff/00000000000000003b9b402f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;
}

@font-face {
font-family:"lexia";
src:url("https://use.typekit.net/af/3c0bc8/00000000000000003b9b4030/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/3c0bc8/00000000000000003b9b4030/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/3c0bc8/00000000000000003b9b4030/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:400;
}

@font-face {
font-family:"lexia";
src:url("https://use.typekit.net/af/32c42b/00000000000000003b9b4031/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/32c42b/00000000000000003b9b4031/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/32c42b/00000000000000003b9b4031/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:700;
}

@font-face {
font-family:"lexia";
src:url("https://use.typekit.net/af/a263af/00000000000000003b9b4032/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/a263af/00000000000000003b9b4032/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/a263af/00000000000000003b9b4032/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:700;
}

.tk-lexia-advertising { font-family: "lexia-advertising",serif; }
.tk-lexia { font-family: "lexia",serif; }

