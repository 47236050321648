:root {
	--background-color: rgba(114, 119, 143, 1.0);

	--peer-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
	--peer-border: 1px solid rgba(255, 255, 255, 0.15);
	--peer-empty-avatar: url('./images/buddy.svg');
	--peer-bg-color: rgba(42, 75, 88, 0.9);
	--peer-video-bg-color: rgba(0, 0, 0, 0.75);

	--active-speaker-border-color: rgba(255, 255, 255, 1.0);
	--selected-peer-border-color: rgba(55, 126, 255, 1.0);
}

html
{
	height: 100%;
	width: 100%;
	font-family: 'Roboto';
	font-weight: 300;
	margin : 0;
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
}

body
{
	height: 100%;
	width: 100%;
	font-size: 16px;
	margin: 0;
}

#edumeet
{
	height: 100%;
	width: 100%;
}

/************* CUSTOM BOOM BRANDING **************/

.MuiFab-root {
    background-color: #00000066 !important;
    color: white !important;
    border-radius: 7px !important;
}

.MuiFab-root:hover {
    background-color: #000000b0 !important;
}

.MuiFab-secondary,.MuiButton-containedSecondary {
    color: #fff;
    background-color: #b10000ab !important;
}

.MuiAppBar-colorPrimary {
     background-color: #0000004d !important;
}


/*
.webcam>div:first-child>div:first-child {
    background-color: #b10000ab !important;
    border-radius: 7px !important;
}
*/

.webcam.hover,.screen.hover {
    box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.05) inset, 0px 0px 0px 2px rgb(255 255 255 / 90%) !important;
}

.webcam {
    background-color: rgb(66 166 244 / 6%) !important;
}

.MuiDrawer-paper .MuiButton-containedSecondary {
    color: #fff;
    background-color: #b10000 !important;
}

.MuiDrawer-paper>div, .MuiDrawer-paper>div>.MuiPaper-elevation1 {
    background-color: #e9e9e9 !important;
}

.MuiButton-containedSecondary {
    background-color: #b10000 !important;
}

.MuiFab-secondary:hover {
    background-color: rgb(177 0 0)!important;
}

/*
.webcam>div:first-child>div:nth-child(3)>div:first-child>div:nth-child(2) {
    background-color: #00000057 !important;
    padding: 4px !important;
}
*/